<template>
  <div style="width: 100%" class="fill-height">
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="4" class="justify-center" :class="['px-0']">
          <v-card>
            <v-card-title
              class="d-flex"
              style="
                vertical-align: middle;
                background-color: #1577da;
                height: 58px;
                color: white;
              "
            >
              <span class="text-h5" style="font-weight: bold"
                >Dodavanje firme na support</span
              >
            </v-card-title>
            <v-card-text>
              <v-row class="pt-5 pr-4 pl-3">
                <div style="width: 100%" class="pr-0 pl-0" cols="8">
                  <v-autocomplete
                    class="pb-1"
                    outlined
                    clearable
                    dense
                    label="Odaberite firmu"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    return-object
                    hide-details
                    v-model="selectedCompany"
                  ></v-autocomplete>
                </div>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
              <v-btn
                id="success"
                class="btn successButton"
                @click="addAdminUser()"
              >
                Dodaj firmu
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import state from '@/state'
import { auth, df, functions } from '@/plugins/firebase'
// import { clone } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  name: 'CompanyAdd',
  mixins: [applicationSettings],
  inject: ['showLoader', 'hideLoader', 'showMsgBox'],
  components: { },
  data: () => ({
    companies: [],
    userCompanies: [],
    selectedCompany: undefined,
    listeners: []
  }),
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getUserCompanies()
    // this.getCompanies()
  },
  beforeDestroy () {
  },
  methods: {
    getUserCompanies () {
      const docRef = df.doc(`user_companies/${auth.currentUser.uid}`)
      this.unsubscribe = docRef.onSnapshot((doc) => {
        const data = doc.data()
        if (data?.companies) {
          this.userCompanies = []
          this.userCompanies = Object.keys(data.companies)
            .map((key) => {
              return data.companies[key]
            })
            .filter((company) => company?.resource_status === 'ACTIVE')
            .sort((company1, company2) => {
              if (company1.id < company2.id) return -1
              if (company1.id > company2.id) return 1
            })
          this.getCompanies()
        }
      })
    },
    getCompanies () {
      this.companies = []
      const query = df.collection('companies')
        .orderBy('name', 'asc')

      const listener = query
        .onSnapshot((doc) => {
          this.companies = []
          doc.docs.forEach((rec) => {
            var companyForAdd = rec.data()
            const found = this.userCompanies.find(
              (company) =>
                company.id === companyForAdd.id
            )

            if (!found) {
              this.companies.push(companyForAdd)
            }
          })

          this.companies = this.companies.sort((a, b) => a.name.localeCompare(b.name))

          if (doc.docs.length === 0) {
            this.companies = []
          }
        }
        )

      this.listeners.push(listener)
    },
    addAdminUser () {
      this.showLoader()
      var userApi = functions.httpsCallable('userapi')

      var params = {
        company_id: this.selectedCompany.id,
        user_id: 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2'
      }

      userApi({
        action: {
          operation: 'set',
          entity: 'admin_user',
          params: params
        }
      })
        .then(async (result) => {
          this.hideLoader()
          if (result.header.code === 200) {
            this.showMsgBox({
              text: 'Uspješna izmjena!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          } else {
            this.showMsgBox({
              text: 'Greška prilikom spremanja podataka.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }
        })
    }
  }

}
</script>
<style scoped>
</style>
